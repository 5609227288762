<template>
  <v-container class="p-0">
    <v-card class="p-0">
      <v-row>
        <v-col cols="1" class="p-0 pl-5 pt-2">
          <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        </v-col>
        <v-col cols="11" class="p-0">
          <PageHeader :title="'Nuevo proceso actualización de variables'" />
        </v-col>
      </v-row>

      <v-card-text class="py-0">
        <v-form v-model="isFormValid" ref="act-variables-form">
          <v-row>
            <v-col cols="12" md="8" class="py-1">
              <v-autocomplete
                dense
                outlined
                v-model="selectedProcedure"
                :items="procedures"
                clearable
                item-text="value"
                item-value="id"
                hide-details="auto"
                label="Procedimiento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="py-1">
              <v-text-field
                dense
                hide-details="auto"
                outlined
                :rules="[rules.maxLength(variableName, 100)]"
                v-model="variableName"
                label="Nombre de variable"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-1">
              <currency-input
                v-model="porcentajeActualizacion"
                :append-icon="porcentajeIcon"
                dense
                :hideDetails="'auto'"
                :rules="
                  rules.required.concat(
                    rules.decimalValidRanges(porcentajeActualizacion, 0, 500)
                  )
                "
                :options="percentOptions"
                label="Porcentaje de actualización (*)"
                outlined
              >
              </currency-input>
            </v-col>
            <v-col cols="12" md="3" class="py-1">
              <v-text-field
                v-model="nuevoPeriodoDesde"
                hint="Formato AAAAMM p/ inicio de nueva vigencia"
                dense
                hide-details="auto"
                :rules="rules.required.concat(rules.periodoYyyyMm)"
                v-mask="'######'"
                label="Período desde (*)"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="py-1">
              <v-autocomplete
                v-model="tipoRedondeoSelected"
                :items="tipoRedondeo"
                item-text="value"
                item-value="id"
                hide-details="auto"
                outlined
                dense
                autocomplete="off"
                label="Tipo de redondeo"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <span>(*) Campos requeridos</span>
            </v-col>
            <v-col cols="12" md="2" class="py-1 text-right">
              <v-btn
                @click="applyFilter"
                :disabled="!isFormValid || loadingVariables"
                color="primary"
                >Aplicar</v-btn
              >
            </v-col>
            <v-col
              cols="12"
              class="pl-5 py-2"
              md="12"
              v-if="variables.some(x => x.vigenciaValida == false)"
            >
              <span :style="'color:red'">
                Los registros en rojo no se podrán actualizar por superposición de vigencias.
              </span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    <!-- </v-card>

    <v-card> -->
      <v-card-text class="py-0">
        <v-data-table
          :headers="headers"
          :items="variables"
          item-key="rgVarId"
          :search="search"
          :loading="loadingVariables"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" align="end">
                  <v-btn
                    :disabled="!variables.length > 0 || loadingProceso"
                    color="primary"
                    class="to-right"
                    @click="grabarProcesoActualizacion()"
                  >
                    Grabar proceso
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.rgVarNombre`]="{ item }">
            <span :style="!item.vigenciaValida ? 'color:red' : ''">
              {{ item.rgVarNombre }}</span
            >
          </template>
          <template v-slot:[`item.rgVarValor`]="{ item }">
            <span :style="!item.vigenciaValida ? 'color:red' : ''">{{
              helpers.floatToMoneyString(item.rgVarValor, "", true)
            }}</span>
          </template>
          <template v-slot:[`item.nuevoValor`]="{ item }">
            <span :style="!item.vigenciaValida ? 'color:red' : ''">{{
              helpers.floatToMoneyString(item.nuevoValor, "", true)
            }}</span>
          </template>
          <template v-slot:[`item.vigenciaAnterior`]="{ item }">
            <span :style="!item.vigenciaValida ? 'color:red' : ''">{{
              `${item.rgVarValorPeriodoDesde} - ${item.rgVarValorPeriodoHasta}`
            }}</span>
          </template>
          <template v-slot:[`item.nuevaVigencia`]="{ item }">
            <span :style="!item.vigenciaValida ? 'color:red' : ''">{{
              `${item.nuevoPeriodoDesde} - ${item.nuevoPeriodoHasta}`
            }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteValorVariable(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import FiltersSelected from "@/components/shared/FiltersSelected";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import rules from "@/utils/helpers/rules";
import helpers from "@/utils/helpers.js";

export default {
  name: "NuevoProcesoActualizacionVariables",
  components: {
    PageHeader,
    GoBackBtn,
    FiltersSelected,
    CurrencyInput
  },
  directives: { mask },

  data() {
    return {
      selectedProcedure: null,
      routeToGo: "ProcesosActualizacionVariables",
      isFormValid: false,
      variableName: "",
      percentage: null,
      porcentajeIcon: enums.icons.PERCENT_OUTLINE,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      rules: rules,
      search: "",
      porcentajeActualizacion: 0,
      procedures: [], // This should be populated with your procedure data
      variables: [],
      percentOptions: {
        locale: "es-ES",
        currency: "USD",
        currencyDisplay: "hidden",
        valueRange: {
          min: 0,
          max: 500
        },
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: false,
        accountingSign: false
      },
      tipoRedondeoSelected: 0,
      helpers: helpers,
      nuevoPeriodoDesde: "",
      tipoRedondeo: [
        {
          id: 0,
          value: "Sin redondeo"
        },
        {
          id: 1,
          value: "Unidad"
        },
        {
          id: 10,
          value: "Decena"
        },
        {
          id: 100,
          value: "Centena"
        }
      ],
      loadingVariables: false,
      loadingProceso: false,
      headers: [
        { text: "Variable", align: "start", value: "rgVarNombre" },
        // { text: "Procedimiento", value: "rgProcNombre" },
        {
          text: "Valor Actual",
          align: "end",
          value: "rgVarValor",
          sortable: false
        },
        {
          text: "Vigencia actual",
          value: "vigenciaAnterior",
          align: "center",
          sortable: false
        },
        {
          text: "Nuevo valor",
          align: "end",
          value: "nuevoValor",
          sortable: false
        },
        {
          text: "Nueva vigencia",
          value: "nuevaVigencia",
          align: "center",
          sortable: false
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "12%",
          align: "end"
        }
      ]
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  mounted() {
    this.getProcedimientosReglasComerciales();
    this.setPeriodoDesde();
  },
  methods: {
    ...mapActions({
      getVariablesNuevoProcesoActualizacion:
        "configuracion/getVariablesNuevoProcesoActualizacion",
      saveProcesoActualizacionVariablesComerciales:
        "configuracion/saveProcesoActualizacionVariablesComerciales",
      getProcedimientosReglasComercialesConVariables:
        "configuracion/getProcedimientosReglasComercialesConVariables",
      setAlert: "user/setAlert"
    }),
    async getProcedimientosReglasComerciales() {
      const response = await this.getProcedimientosReglasComercialesConVariables();
      this.procedures = response;
    },
    setPeriodoDesde() {
      let today = new Date();
      let month = (today.getMonth() + 1).toString().padStart(2, "0");
      this.nuevoPeriodoDesde = `${today.getFullYear()}${month}`;
    },
    deleteValorVariable(item) {
      this.variables = this.variables.filter(
        x => x.rgVarValorId !== item.rgVarValorId
      );
    },
    async applyFilter() {
      this.loadingVariables = true;
      const payload = {
        rgProcId: this.selectedProcedure,
        rgVarNombre: this.variableName,
        periodoDesde: this.nuevoPeriodoDesde,
        porcentaje: this.porcentajeActualizacion,
        tipoRedondeo: this.tipoRedondeoSelected
      };

      try {
        const response = await this.getVariablesNuevoProcesoActualizacion(
          payload
        );
        this.variables = response;
      } catch (error) {
        console.error("Error fetching variables:", error);
      } finally {
        this.loadingVariables = false;
      }
    },
    async grabarProcesoActualizacion() {
      this.loadingProceso = true;
      const data = {
        variablesNuevaActualizacion: this.variables,
        porcentaje: this.porcentajeActualizacion
      };
      try {
        const response = await this.saveProcesoActualizacionVariablesComerciales(
          data
        );
        if (response != null) {
          this.setAlert({
            type: "success",
            message: "Proceso realizado con éxito."
          });
          this.$router.push({
            name: this.routeToGo
          });
        }
      } catch (error) {
      } finally {
        this.loadingProceso = false;
      }
    }
  }
};
</script>
